import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Select } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import videoImage from './assets/video.png';
import docImage from './assets/doc.png';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tracker from './screens/tracker';
import { LoginScreen } from './screens/login';

function App() {
  return(
    <Router>
      <Routes>
        <Route path='/' element={<Tracker/>} />
        <Route path='/login' element={<LoginScreen/>} />
      </Routes>
    </Router>
  )
}

export default App;
