import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

export function LoginScreen() {
    // useGoogleOneTapLogin({
    //     onSuccess: credentialResponse => {
    //       console.log(credentialResponse);
    //     },
    //     onError: () => {
    //       console.log('Login Failed');
    //     },
    // });
    const navigate = useNavigate();

    return(
        <div style={{ display: 'grid', placeItems: 'center', height: '100vh', width: '100vw' }} > 
            <GoogleOAuthProvider clientId="87156420597-p190lremh616odn71qc4r4hkrnuckigp.apps.googleusercontent.com">
                <div style={{ width: '40vh', display: 'grid', placeItems: 'center'}} className='loginCard' >
                    <p style={{ color: 'black', fontWeight: '600' }}>Login using your PW Google account</p>
                    <GoogleLogin
                    onSuccess={credentialResponse => {
                        localStorage.setItem('id_token', credentialResponse.credential)
                        navigate('/')
                    }}
                    onError={() => {
                        alert('Login Failed');
                    }}
                    />
                </div>
            </GoogleOAuthProvider>
        </div>
    )
}