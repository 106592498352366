import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Select } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import videoImage from '../assets/video.png';
import docImage from '../assets/doc.png';
import demoImage from '../assets/demo.png';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { MultiSelect } from '@mantine/core';
import edit from '../assets/edit.png';
import reload from '../assets/reload.png';
import link from '../assets/link.png';

const csvHeaders = [
  { label: 'id', key: '_id' },
  { label: 'key', key: 'fileKey' },
  { label: 'Center', key: 'center' },
  { label: 'Room', key: 'room' },
  { label: 'Filename', key: 'fileName' },
  { label: 'File-Type', key: 'fileType' },
  { label: 'Start-time', key: 'startTime' },
  { label: 'End-time', key: 'stopTime' },
  { label: 'Uploaded', key: 'uploaded' },
  { label: 'Processed', key: 'isProcessed' },
  { label: 'Scheduled', key: 'scheduled' },
]

function Tracker() {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [currentCenter, setCurrentCenter] = useState(undefined);
  const [currentCenters, setCurrentCenters] = useState(undefined);
  const [currentRoom, setCurrentRoom] = useState(undefined);
  const [currentType, setCurrentType] = useState(undefined);
  const [currentDate, setCurrentDate] = useState(undefined);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [centers, setCenters] = useState([]);
  const [spinnerClass, setSpinnerClass] = useState('invisible');
  const [csvData, setCsvData] = useState([]);

  const [multiScheduleModal, setMultiScheduleModal] = useState(false);
  const [multiScheduleData, setMultiScheduleData] = useState({ schedules: [] });

  const [findOneModal, setFindOneModal] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState(undefined);
  const [allSchedules, setAllSchedules] = useState([]);

  const [editModal, setEditModal] = useState(false);
  const [editDocument, setEditDocument] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [currentTeacher, setCurrentTeacher] = useState('');

  const csvRef = useRef(null);
  
  useEffect(() => {
    axios.get('https://studio-app-api.penpencil.co/v1/studio/files', { headers: { Authorization: localStorage.getItem('id_token') } })
    .then(async (res) => {
      setData(res.data.data);
      const newSheetData = await getSheetData();
      setSheetData(newSheetData);
      const centersData = [];
      newSheetData.forEach((data) => {
        centersData.push({
          value: data.center,
          label: data.center
        })
      })
      setCenters(centersData)
    })
    .catch(() => {
      navigate('/login')
    })
  }, [])

  // useEffect(() => {
  //   if (csvData.length > 0) {
  //     csvRef.current.link.click();
  //   }
  // }, [csvData]);

  const arrayToCsv = (headers, data) => {
    const csvRows = [];
    const headerValues = headers.map(header => header.label); 
    csvRows.push(headerValues.join(`,`));
    { const rowValues = headers.map(header => { 
      const escaped = (`' + row[header.key]).replace(/"/g, '\\"`);
      return `"${escaped}"`}); 
      csvRows.push(rowValues.join(`,`));
      return csvRows.join(`\n`);
    }
  };

  async function fetchAllData() {
      if(!from || !to) {
        alert("Please select a date range!")
        return;
      }
      setSpinnerClass('spinnerContainer')
      let url = `https://studio-app-api.penpencil.co/v1/studio/files/all?from=${from}&to=${to}`;
      if(currentCenters) url = url + `&centers=${JSON.stringify(currentCenters)}`;
      if(currentRoom) url = url + `&room=${currentRoom}`;
      if(currentType) url = url + `&type=${currentType}`;
      axios.get(url, { headers: { Authorization: localStorage.getItem('id_token') } })
      .then((res) => {
        setCsvData(res.data.data);
        setTimeout(() => {
          setSpinnerClass('invisible')
          csvRef.current.link.click();
        }, 3000)
        return;
      })
      .catch((e) => {
        setSpinnerClass('invisible')
        return;
      })
  }

  async function getSheetData() {
    return axios.get('https://sheets.googleapis.com/v4/spreadsheets/1twLjAS4u1ThRhLeWnGVUcxhp5gQ2s2OlRKeZqRW4qLA/values/Master!A:C?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ')
    .then((res) => {
      const sheetData = res.data.values;
      sheetData.shift();
      const outputData = [];
      sheetData.forEach((dataBlock) => {
        if (outputData.some((data) => data.center == dataBlock[0])) {
          const index = outputData.findIndex((data) => data.center == dataBlock[0])
          if(outputData[index].rooms.indexOf(dataBlock[1]) == -1) outputData[index].rooms.push(dataBlock[1]);
          outputData[index].rooms = outputData[index].rooms.sort()
        }
        else {
          outputData.push({
            center: dataBlock[0],
            rooms: [dataBlock[1]]
          })
        }
      })
      return outputData;
    })
  }

  async function findAllSchedules(fileId) {
    setSpinnerClass('spinnerContainer')
    axios.get(`https://studio-app-api.penpencil.co/v1/studio/files/${fileId}/schedules`, { headers: { Authorization: localStorage.getItem('id_token') } })
    .then((res) => {
      setSpinnerClass('invisible')
      setAllSchedules(res.data.data);
      setFindOneModal(true)
    })
    .catch((e) => {
      setSpinnerClass('invisible')
      toast.error(`An error occured`)
    })
  }

  async function getFacultyData(center, room) {
    return axios.get(`https://sheets.googleapis.com/v4/spreadsheets/1OTnzm9gKVce4edD4qWZzeemvmjQknZbon5rLbc8xtAE/values/Master!A:D?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ`)
    .then(async (res) => {
        const sheetData = res.data.values
        const headers = sheetData.shift();
        const outputData = {center, room, batches: [], teachers: []};
        sheetData.forEach((dataBlock) => {
            if(dataBlock[0] == center && dataBlock[1] == room) {
                outputData.batches.push(dataBlock[3])
            }
        })
        return outputData
    })
    .catch((error) => {
        throw error
    })
  }

  async function getAllTeachers(center) {
    return axios.get(`https://sheets.googleapis.com/v4/spreadsheets/1OTnzm9gKVce4edD4qWZzeemvmjQknZbon5rLbc8xtAE/values/Teachers!A:E?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ`)
    .then(async (res) => {
        const sheetData = res.data.values
        const headers = sheetData.shift();
        const outputData = {center, teachers: []};
        sheetData.forEach((dataBlock) => {
            if(dataBlock[0] == center) {
                outputData.teachers.push({
                  name: dataBlock[1],
                  email: dataBlock[2],
                  driveId: dataBlock[4],
                })
            }
        })
        return outputData
    })
    .catch((error) => {
        throw error
    })
  }

  const [pingData, setPingData] = useState([]); 
  
  useEffect(() => {
    axios.get('https://studio-app-api.penpencil.co/v1/studio/ping', { headers: { Authorization: localStorage.getItem('id_token') } })
    .then((res) => {
      const pings = res.data.data.sort()
      const pingData = [];
      pings.forEach((e) => {
        pingData.push({ value: e, label: e })
      })
      setPingData(pingData);
    })
    .catch(() => {
      navigate('/login')
    })
  }, [])

  return(
    <div className='mainContainer' >
      <Toaster />
      <div className={spinnerClass} >
        <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>
      </div>

      {
        multiScheduleModal ? 
        <div className='multiScheduleModalContainer' onClick={() => setMultiScheduleModal(!multiScheduleModal)} >
          <div className='multiScheduleModal' onClick={(e) => {
            e.stopPropagation()
          }} >
            <p>Select A Schedule</p>
            {/* <span style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', width: '100%', justifyContent: 'center', alignItems: 'center', placeItems: 'center' }} >
              <p>Select A Schedule</p>
              <button className='button findAllButton' onClick={() => {
                findAllSchedules(multiScheduleData._id)
                setCurrentDocumentId(multiScheduleData._id)
                setMultiScheduleModal(!multiScheduleModal)
              }} >Find All</button>
            </span> */}
            <div className='schedulesContainer' >
              {
                multiScheduleData.schedules.map((e, index) => {
                  return(
                    <div className='scheduleCard' >
                      <span>
                        <p>{e.batchNameProp} - {e.batchSubjectId.subjectId.name}</p>
                        {e.topic ? <p>{e.topic}</p>: <p>--No Title--</p>}
                        <p>{new Date(e.startTime).toLocaleString()}</p>
                      </span>
                      <button className='button' onClick={(i) => {
                        i.stopPropagation()
                        // 
                        axios.patch(`https://studio-app-api.penpencil.co/v1/studio/files/${multiScheduleData._id}/select-schedule/${index}`, {}, { headers: { Authorization: localStorage.getItem('id_token') } })
                        .then((res) => {
                          toast.success(`Video should be now processed shortly!`, {
                            style: {
                              backgroundColor: '#181414',
                              color: '#fff',
                              fontFamily: 'Metropolis'
                            },
                            iconTheme: {
                              primary: '#5a4bda',
                              secondary: '#FFFAEE',
                            },
                          })
                        })
                        .catch(() => {
                          toast.success(`An unexpected error occured!`, {
                            style: {
                              backgroundColor: '#181414',
                              color: '#fff',
                              fontFamily: 'Metropolis'
                            },
                            iconTheme: {
                              primary: '#5a4bda',
                              secondary: '#FFFAEE',
                            },
                          })
                        })
                      }} >Select</button>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div> :
        null
      }

      {
        findOneModal ? 
        <div className='multiScheduleModalContainer' onClick={() => setFindOneModal(!findOneModal)} >
          <div className='multiScheduleModal' onClick={(e) => {
            e.stopPropagation()
          }} >
            <p>Select A Schedule</p>
            <div className='schedulesContainer' >
              {
                allSchedules.allSchedules.map((e, index) => {
                  return(
                    <div className='scheduleCard' >
                      <span>
                        <p>{e.batchNameProp} - {e.batchSubjectId.subjectId.name}</p>
                        {e.topic ? <p>{e.topic}</p>: <p>--No Title--</p>}
                        <p>{new Date(e.startTime).toLocaleString()}</p>
                      </span>
                      <button className='button' onClick={(i) => {
                        i.stopPropagation()
                        // 
                        axios.patch(`https://studio-app-api.penpencil.co/v1/studio/files/${allSchedules._id}/select-schedule/${index}?all=true`, {}, { headers: { Authorization: localStorage.getItem('id_token') } })
                        .then((res) => {
                          toast.success(`Video should be now processed shortly!`, {
                            style: {
                              backgroundColor: '#181414',
                              color: '#fff',
                              fontFamily: 'Metropolis'
                            },
                            iconTheme: {
                              primary: '#5a4bda',
                              secondary: '#FFFAEE',
                            },
                          })
                        })
                        .catch(() => {
                          toast.success(`An unexpected error occured!`, {
                            style: {
                              backgroundColor: '#181414',
                              color: '#fff',
                              fontFamily: 'Metropolis'
                            },
                            iconTheme: {
                              primary: '#5a4bda',
                              secondary: '#FFFAEE',
                            },
                          })
                        })
                      }} >Select</button>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div> :
        null
      }

      {
        editModal ? 
        <div className='multiScheduleModalContainer' onClick={() => {
            setEditModal(false)
            setEditDocument({})
            setCurrentTeacher("")
            setTeachers([])
        }} >
          <div className='multiScheduleModal' style={{ height: '230px' }} onClick={(e) => {
            e.stopPropagation()
          }} >
            <p>Edit File</p>
            <div className='editContainer' >
              <span className='stackedSpan' style={{ alignItems: 'center', width: '90%' }} >
                <input id="edit-title" className='titleInput' type='text' defaultValue={editDocument.scheduleDetails.topic} />
                {/* <img src={edit} style={{ marginBottom: '3px' }}  onClick={(e) => {
                  e.stopPropagation()
                  console.log("input clicked")
                  document.getElementById("edit-title").disabled = !document.getElementById("edit-title").disabled
                }} /> */}
              </span>
              {editDocument.fileType == "video" ? <Select
                placeholder="Select Teacher"
                data={teachers}
                style={{ width: '90%' }}
                searchable
                onChange={(e) => {
                  setCurrentTeacher(e);
                }}
              />: null}
              <button className='button downloadCsv' onClick={() => {
                const payload = {}
                if(editDocument.scheduleDetails.topic != document.getElementById("edit-title").value) {
                  payload.title = document.getElementById("edit-title").value;
                }
                if(editDocument.scheduleDetails.teacherImageId != currentTeacher && currentTeacher !== "") {
                  payload.teacherDriveId = currentTeacher;
                }
                if(!payload.teacherDriveId && !payload.title) return;
                setSpinnerClass('spinnerContainer')
                axios.post(`https://studio-app-api.penpencil.co/v1/studio/files/${editDocument._id}/edit-schedule`, payload, 
                { headers: { Authorization: localStorage.getItem('id_token') } })
                .then(() => {
                  setSpinnerClass('invisible')
                  setEditModal(false)
                  setEditDocument({})
                  setCurrentTeacher("")
                  setTeachers([])
                  toast.success(`Video will be edited shortly`, {
                    style: {
                      backgroundColor: '#181414',
                      color: '#fff',
                      fontFamily: 'Metropolis'
                    },
                    iconTheme: {
                      primary: '#5a4bda',
                      secondary: '#FFFAEE',
                    },
                  })
                  .catch(() => {
                    setSpinnerClass('invisible')
                    toast.error(`Unexpected error occured!`, {
                      style: {
                        backgroundColor: '#181414',
                        color: '#fff',
                        fontFamily: 'Metropolis'
                      },
                      iconTheme: {
                        primary: '#5a4bda',
                        secondary: '#FFFAEE',
                      },
                    })
                  })
                })
              }} style={{ paddingLeft: '2em', paddingRight: '2em' }} >Save</button>
            </div>
          </div>
        </div> :
        null
      }

      
      <div className='filtersContainer' >
      <MultiSelect
        placeholder="Select Center"
        data={centers}
        searchable
        style={{ width: '20vw' }}
        onChange={(e) => {
          let url = `https://studio-app-api.penpencil.co/v1/studio/files?centers=${JSON.stringify(e)}`;
          if(currentRoom) url = url + `&room=${currentRoom}`;
          if(currentType) url = url + `&type=${currentType}`;
          axios.get(url, { headers: { Authorization: localStorage.getItem('id_token') } })
          .then(async (res) => {
            setCurrentCenters(e);
            setData(res.data.data);
            if(e.length == 1) {
              const centerRooms = sheetData.filter((a) => a.center == e[0])[0].rooms;
              const roomsData = [];
              centerRooms.forEach((e) => {
                roomsData.push({
                  value: e,
                  label: e
                })
              })
              setRooms(roomsData)
            }
            else {
              setRooms([]);
            }
          })
          .catch((e) => {
            console.log(e)
            // navigate('/login')
          })
        }}
      />
        <Select
          placeholder="Select Room"
          data={rooms}
          searchable
          onChange={(e) => {
            let url = `https://studio-app-api.penpencil.co/v1/studio/files?room=${e}`;
            if(currentCenters) url = url + `&centers=${JSON.stringify(currentCenters)}`;
            if(currentType) url = url + `&type=${currentType}`;
            axios.get(url, { headers: { Authorization: localStorage.getItem('id_token') } })
            .then(async (res) => {
              setCurrentRoom(e);
              setData(res.data.data);
            })
            .catch(() => {
              navigate('/login')
            })
          }}
        />
        <Select
          placeholder="File Type"
          data={[{ value: 'video', label: 'Video' }, { value: 'notes', label: 'Notes' }, { value: 'demo', label: 'Demo' }]}
          searchable
          onChange={(e) => {
            let url = `https://studio-app-api.penpencil.co/v1/studio/files?type=${e}`;
            if(currentCenters) url = url + `&centers=${JSON.stringify(currentCenters)}`;
            if(currentRoom) url = url + `&room=${currentRoom}`;
            setCurrentType(e)
            axios.get(url, { headers: { Authorization: localStorage.getItem('id_token') } })
            .then(async (res) => {
              setData(res.data.data);
            })
            .catch(() => {
              navigate('/login')
            })
          }}
        />
        <DateInput
          valueFormat="YYYY MMM DD"
          placeholder="From"
          maw={400}
          mx="auto"
          style={{ margin: '0px' }}
          onChange={(e) => {
            const date = new Date(e)
            setFrom(date.toISOString())
          }}  
        />
        <DateInput
          valueFormat="YYYY MMM DD"
          placeholder="To"
          maw={400}
          mx="auto"
          style={{ margin: '0px' }}
          onChange={(e) => {
            const date = new Date(new Date(e).getTime() + 86400000)
            setTo(date.toISOString())
          }}  
        />
        <Select
          placeholder="Online clients"
          data={pingData}
          searchable
          style={{ width: '20em' }}
        />
        
        <CSVLink data={csvData} ref={csvRef} headers={csvHeaders} filename='export.csv'/>
        <button className='button downloadCsv' onClick={fetchAllData} >Download CSV</button>
        <button className='button downloadCsv' onClick={() => {navigate(0)}} >Reset Filters</button>
      </div>

      <div className='documentCardContainer' >
      <div className='documentCard' >
        <span className='fileTypeContainer' >
          <span className='centerDetailsSpan' style={{  }} >
            <p className='centerName' >Center - Room</p>
            <p>Class run-time</p>
          </span>
        </span>
        <p style={{ textAlign: 'center', fontWeight: 500 }} >Late By</p>
        <span className='fileNameTypeSpan' >
          <p className='fileName' style={{ fontWeight: 500 }}  >Filename</p>
        </span>
        <span className='statusSpan' >
          <p className='pTagNoMargins' style={{ fontWeight: 500 }} >Status</p>
          <span className='statusIndicators' >
            <p className='pTagNoMargins' >U</p>
            <p className='pTagNoMargins' >P</p>
            <p className='pTagNoMargins' >S</p>
          </span>
        </span>
        <span>
            <p style={{ fontWeight: 500 }} >Video Thumbnail</p>
        </span>
        {/* <span>
          { document.scheduleDetails ? 
            <span className='uploadedTextSpan' >
              <p>Schedule name: {document.scheduleDetails.topic}</p>
            </span> :
            <img className='thumbnail' src={`https://blog.thomasnet.com/hubfs/shutterstock_774749455.jpg`} /> 
          } 
        </span> */}
        <span style={{ width: '100%' }} >
          <p style={{ fontWeight: 500, textAlign: 'center', width: '100%' }} >Actions</p>
        </span>
      </div>

      {
      data.map((document) => {
        if(currentDate) {
          if(String(document.startTime).split('T')[0] != String(currentDate).split('T')[0]) return null
        }
        if(!document.isProcessed && document.uploaded && document.fileType == "notes") {
          return
        } 
        return <div className='documentCard' >
          <span className='fileTypeContainer' >
            <span className='centerDetailsSpan' >
              <p className='centerName' style={{ fontWeight: 400 }} >{document.center} - {document.room}</p>
              <p>Time: {String(new Date(document.startTime).toLocaleString()).split(', ')[1]} - {String(new Date(document.stopTime).toLocaleString()).split(', ')[1]}</p>
            </span>
            {document.fileType == 'video' ? <img className='fileTypeIcon' src={videoImage}/> : document.fileType == 'notes' ? <img className='fileTypeIcon' src={docImage}/> : <img className='fileTypeIcon' src={demoImage}/>}
          </span>
          <span style={{ textAlign: 'center' }} >
            {
              document.fileType == 'video' ?
              document.scheduleDetails ? <p>{Math.floor((new Date(document.startTime) - new Date(document.scheduleDetails.startTime))/60000)}</p> : <p>-</p> :
              document.scheduleDetails ? <p>{Math.floor((new Date(document.stopTime) - new Date(document.scheduleDetails.endTime))/60000)}</p> : <p>-</p> 
            }
          </span>
          <span className='fileNameTypeSpan' >
            <p className='fileName'>{String(document.fileName).split('.')[0]}</p>
          </span>
          <span className='statusSpan' >
            <p className='pTagNoMargins' >Status</p>
            <span className='statusIndicators' >
              <p className='pTagNoMargins' >{document.uploaded ? '✅' : '🔴'}</p>
              <p className='pTagNoMargins' >{document.isProcessed ? '✅' : '🔴'}</p>
              <p className='pTagNoMargins' >{document.scheduled ? '✅' : '🔴'}</p>
            </span>
          </span>
          <span>
            { document.youtubeId ? 
              <img className='thumbnail' src={`https://img.youtube.com/vi/${document.youtubeId}/mqdefault.jpg`} /> :
              // <img className='thumbnail' src={`https://blog.thomasnet.com/hubfs/shutterstock_774749455.jpg`} /> 
              <span className='selectOneSpan' >
                {document.schedules ? <p>Found {document?.schedules?.length} schedules</p> : <p>Unable to publish video!</p>}
                <button className={document?.schedules?.length > 1 && !document.scheduled ? 'button selectOneButton' : 'invisible'} onClick={() => {
                  setMultiScheduleData(document);
                  setMultiScheduleModal(true)
                }} >Select One</button>
                <button className={document?.schedules?.length == 0 && !document.scheduled ? 'button selectOneButton' : 'invisible'} onClick={() => {
                  findAllSchedules(document._id)
                  setCurrentDocumentId(document._id)
                }} >Find One</button>
              </span>
            } 
          </span>
          {/* <span>
            { document.scheduleDetails ? 
              <span className='uploadedTextSpan' >
                <p>Schedule name: {document.scheduleDetails.topic}</p>
              </span> :
              <img className='thumbnail' src={`https://blog.thomasnet.com/hubfs/shutterstock_774749455.jpg`} /> 
            } 
          </span> */}
          <span className='stackedSpan' >
            {document.scheduled ? <img src={edit} onClick={async () => {
              setSpinnerClass('spinnerContainer')
              const data = await getAllTeachers(document.center);
              data.teachers.forEach((teacher, index) => {
                data.teachers[index] = {
                  label: teacher.name,
                  value: teacher.driveId
                }
              })
              console.log(data)
              setTeachers(data.teachers)
              setSpinnerClass('invisible')
              setEditDocument(document)
              setEditModal(true);
            }} />: null }
            <img src={link}  onClick={() => {
              if(document.youtubeId) {
                navigator.clipboard.writeText(`https://www.youtube.com/watch?v=${document.youtubeId}`);
                toast.success(`Link copied to clipboard`, {
                  style: {
                    backgroundColor: '#181414',
                    color: '#fff',
                    fontFamily: 'Metropolis'
                  },
                  iconTheme: {
                    primary: '#5a4bda',
                    secondary: '#FFFAEE',
                  },
                })
              }
              else {
                setSpinnerClass('spinnerContainer')
                let url = `https://studio-app-api.penpencil.co/v1/studio/files/signed-url/${document._id}`
                axios.get(url, { headers: { Authorization: localStorage.getItem('id_token') } })
                .then((res) => {
                  navigator.clipboard.writeText(res.data.data.publicUrl);
                  toast.success(`Link copied to clipboard`, {
                    style: {
                      backgroundColor: '#181414',
                      color: '#fff',
                      fontFamily: 'Metropolis'
                    },
                    iconTheme: {
                      primary: '#5a4bda',
                      secondary: '#FFFAEE',
                    },
                  })
                  setSpinnerClass('invisible');
                })
                .catch(() => {
                  toast.error(`An error occured!`)
                  setSpinnerClass('invisible')
                  navigate('/login')
                })
              }
            }}/>
            <img src={reload} onClick={() => {
              setSpinnerClass('spinnerContainer')
              axios.patch(`https://studio-app-api.penpencil.co/v1/studio/upload/${document._id}`, {
                "isProcessed": false
              }, { headers: { Authorization: localStorage.getItem('id_token') } })
              .then((res) => {
                toast.success(`Trying to reupload`, {
                  style: {
                    backgroundColor: '#181414',
                    color: '#fff',
                    fontFamily: 'Metropolis'
                  },
                  iconTheme: {
                    primary: '#5a4bda',
                    secondary: '#FFFAEE',
                  },
                })
                setSpinnerClass('invisible')
              })
              .catch(() => {
                toast.error(`An error occured`)
                setSpinnerClass('invisible')
                navigate('/login')
              })
            }}/>
          </span>
        </div>
      })
      }

      <span className='loadMoreContainer' >
        <button className='button loadMoreButton' onClick={() => {
          let url = `https://studio-app-api.penpencil.co/v1/studio/files?skip=${data.length}`;
          if(currentCenters) url = url + `&centers=${JSON.stringify(currentCenters)}`;
          if(currentRoom) url = url + `&room=${currentRoom}`;
          if(currentType) url = url + `&type=${currentType}`;
          axios.get(url, { headers: { Authorization: localStorage.getItem('id_token') } })
          .then(async (res) => {
            const newData = res.data.data;
            setData([...data, ...newData]);
          })
          .catch(() => {
            navigate('/login')
          })
        }} >Load More</button>
      </span>
      </div>
    </div>
  )
}

export default Tracker;
